<template>
  <div class="comment-input">
    <div class="edit-value" v-if="editing">
      <b-form-textarea
        v-model="updateV"
        rows="2"
        class="textarea"
        @keyup.enter="update"
        @keyup.esc="edit(false)"
        ref="autofocus"
      ></b-form-textarea>
    </div>
    <div class="show-value" v-else @click="edit(true)">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    value: { required: true },
    updateFunction: { required: true }
  },
  data() {
    return {
      editing: false,
      updateValue: this.value
    };
  },
  methods: {
    edit(payload) {
      this.editing = payload;
    },
    cancel() {
      this.editing = false;
      this.updateValue = this.value;
    },
    async update() {
      if (this.value != this.updateValue) {
        await this.updateFunction({
          validation: this.item.validation,
          id: this.item.id,
          row: this.item,
          comment: this.updateValue
        });
        this.updateValue = this.value;
      }
      this.editing = false;
    }
  },
  computed: {
    updateV: {
      get() {
        return this.value;
      },
      set(updateV) {
        this.updateValue = updateV;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.comment-input {
  .edit-value {
    display: flex;
    .textarea {
      font-size: 10px;
      background-color: rgb(222 218 218 / 38%);
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
